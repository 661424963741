<template>
  <div
    class="cover-photo"
  >
    <div
      :class="{ 'cover-photo__title--shadow': shadow }"
      class="cover-photo__title"
    >
      <h1>
        <slot/>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.cover-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  background-image: url('/cover-photo.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  &__title {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &--shadow {
      background-color: rgba(0, 0, 0, 0.4);
    }

    h1 {
      color: $color-white;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &::after {
        content: '';
        width: 86%;
        height: 2px;
        display: block;
        background-color: $color-red;
      }
    }
  }
}
</style>
