/* eslint-disable max-len */
export default [
  {
    title: 'Bobcat E 50',
    text: 'váha:  5500 kg<br/>výška: 2532 mm<br/>šířka: 1960 mm<br/>hloubkový dosah: 3524 mm<br/>příslušenství: lopata 60 a 30 cm, hydraulická svahovací lopata 120 cm, kladivo 235 kg',
    photosPath: 'technika/Bobcat E 50',
    photos: [],
  },
  {
    title: 'BOBCAT S 650',
    text: 'váha: 3657 kg<br/>výška: 2065 mm<br/>šířka: 1832 mm<br/>výška zdvihu: 3149 mm<br/>nosnost: 1450 kg<br/>příslušenství: lopata, paletové vidle, míchací lopata, zametací koště',
    photosPath: 'technika/Bobcat S 650',
    photos: [],
  },
  {
    title: 'MAN 12 180 KONTEJNER (4*2)',
    text: 'váha: 5950 kg<br/>délka včetně kontejneru: 795 cm<br/>šířka: 241 cm<br/>objem kontejneru: 3m3<br/>nosnost: 6000 kg',
    photosPath: 'technika/Man TGL 12 180',
    photos: [],
  },
  {
    title: 'MAN 33 470 (6*6)',
    text: 'váha: 12850 kg<br/>šířka: 256 cm<br/>délka: 795 cm<br/>nosnost: 13000 kg nebo 8 euro palet<br/>objem korby: 10,5 m3',
    photosPath: 'technika/Man TGS 33 470',
    photos: [],
  },
  {
    title: 'TAKEUCHI TB 135',
    text: 'váha: 3640 kg<br/>výška: 2495 mm<br/>šířka: 1580 mm<br/>hloubkový dosah: 3245 mm<br/>příslušenství: lopata 60 a 30 cm, hydraulická svahovací lopata 120 cm, kladivo 235 kg',
    photosPath: 'technika/Takeuchi TB 135',
    photos: [],
  },
  {
    title: 'TŘÍDIČKA MS MINI',
    text: 'váha: 1200 kg<br/>výška: 2340 mm<br/>šířka: 2250 mm<br/>výkon třídiče: dle vlhkosti a konzistence vstupního materiálu až 120 tun/den',
    photosPath: 'technika/Katrovacka',
    photos: [],
  },
  {
    title: 'MÍCHACÍ LOPATA',
    text: 'šířka: 190 cm<br/>objem: 0,4 m3',
    photosPath: 'technika/Mychaci lopata',
    photos: [],
  },
  {
    title: 'ZAMETACÍ KOŠTĚ',
    text: 'šířka: 200 cm<br/>objem sběrné nádoby: 0,3 m3',
    photosPath: 'technika/Zametaci koste',
    photos: [],
  },
];
