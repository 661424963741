/* eslint-disable max-len */
export default [
  {
    title: 'VÝSTAVBA RODINNÝCH DOMŮ',
    text: 'Provádíme kompletní výstavby a rekonstrukce domů na klíč. Naše firma spolupracuje se skušenými projektanty a profesionálními řemeslníky v oblasti kovo, elektro a tzb. Jsme schopni postavit prakticky cokoliv si budete přát včetně oplocení,z ahradních úprav, opěrných zdí a příjezdových cest tak aby jste byli nad míru spokojeni.',
    photosPath: 'reference/rodinne domy',
    photos: [],
  },
  {
    title: 'GABIONY',
    text: 'Provádíme kompletní stavby gabionových opěrných zdí včetně dodávek gabionových sítí stavby košů a ručního nebo strojního vyplnění kamenem.',
    photosPath: 'reference/gabiony',
    photos: [],
  },
  {
    title: 'KANALIZACE A ČOV ZÁMEK LÍŠNO',
    text: 'Stavba nové splaškové gravitační kanalizace, šachet a stavba samostatného podzemního objektu čistírny odpadních vod včetně kompletní dodávky technologie čov.',
    photosPath: 'reference/kanalizace a cov zamek lisno',
    photos: [],
  },
  {
    title: 'ÚPRAVA PŘECHODŮ PODÉL SILNICE 2/603 V OBCI NESPEKY',
    text: 'Kompletní rekonstrukce stávajících chodníků a přechodových ostrůvků včetně konstrukčních vrstev. Výstavba nového veřejného osvětlení přechodů. Stavba nových uličních vpustí a svislého dopravního značení. Realizace pro firmu bes.',
    photosPath: 'reference/uprava prechodu 2 lomeno 603',
    photos: [],
  },
  {
    title: 'MÍSTNÍ KOMUNIKACE STRUHAŘOV',
    text: 'Stavba nové části místní komunikace. Součástí je stavba dvou horských vpustí, nová deštová kanalizace která se napojuje na otevřený destový žlab vyůstující do zasakovocího příkopu a příprava konstrukčních vrstev pod asfaltové povrchy. Subdodávka pro firmu bes.',
    photosPath: 'reference/mistni kom struhar',
    photos: [],
  },
  {
    title: 'CHODNÍK PETROUPIM A PETROUPEC',
    text: 'Chodník podél průtahu obcemi Petroupim a Petroupec včetně souvisejících staveb veřejného osvětlení a částečné výstavby deštové kanalizace a uličních vpustí. Kompletní dodávka stavby pro obec Petroupim.',
    photosPath: 'reference/chodnik petroupim',
    photos: [],
  },
  {
    title: 'STAVBY OPLOCENÍ',
    text: 'Provádíme kompletní oplocení staveb a pozemků včetně podhrabových desek a následných plošných úprav a srovnání terénních nerovností.',
    photosPath: 'reference/stavby oploceni',
    photos: [],
  },
  {
    title: 'REKONSTRUKCE KOMUNIKACE PETROUPIM U BYTOVEK',
    text: 'Rekonstrukce  místní komunikace jejíž součástí byla výstavba chodníku a stání pro kontejnery na odpad. Nové gravitační deštové kanalizace a uličních vpustí a příprava konstrukčních vrstev pod asfaltové povrchy. Realizace pro obec petroupim.',
    photosPath: 'reference/rekon komun petro u bytovek',
    photos: [],
  },
  {
    title: 'VÝSTAVBA MÍSTNÍ KOMUNIKACE V OBCI DUB',
    text: 'Příprava konstrukčních vrstev pod asfaltové povrchy. Vyčištění odvodnovacího příkopu v celé délce nové místní komunikace a následné dosypání krajnic asfaltovým recyklátem včetně osazení dopravních značek. Subdodávka pro firmu bes.',
    photosPath: 'reference/mistni kom dub',
    photos: [],
  },
  {
    title: 'AUTOBUSOVÉ ZASTÁVKY PODÉL SILNICE 2/106 V OBCI CHRÁST nad SÁZAVOU',
    text: 'Výstavba nových autobusových zastávek. Součástí stavby je z jedné strany vsakovací žebro o délce 10 metrů. Nové veřejné osvětlení příslušné dopravní značky a označníky zastávek. Stavba odvodnovacích žlabů po obou stranách zastávek zaůstěných do spadištní šachty a nově zrekonstruovaného propustku. Kompletní realizace pro firmu bes.',
    photosPath: 'reference/autobusove zastavky 106',
    photos: [],
  },
  {
    title: 'ÚPRAVA KŘIŽOVATKY ULICE K PAZDERNĚ A K POTOKU V BENEŠOVĚ',
    text: 'Výstavba chodníků a úprava křižovatky v ulici k pazderně a k potoku a následná stavba přechodového ostrůvku na silnici 2/110 včetně parkovacích stání, nových vpustí deštové kanalizace. Stání pro kontejnery na odpad, veřejného osvětlení a začlenění ploch pro zelen. Realizace pro město benešov.',
    photosPath: 'reference/kriz ulice k pazderne a k potoku',
    photos: [],
  },
];
