<template>
  <div class="show-box" ref="showBox">
    <h2><slot name="title"/></h2>
    <p><slot name="description"/></p>
    <div
      :class="{ 'show-box__photos--mobile': minShowedPhotos === 1 }"
      class="show-box__photos"
    >
      <div
        v-for="([, small], index) in photosToShow"
        :key="`photo-${title}-${index}`"
        @click="showPhoto(`${title} - c.${index}`, index)"
        class="show-box__photo"
      >
        <img :alt="`${title} - c.${index}`" :src="small" loading="lazy"/>
      </div>
    </div>

    <button
      v-if="this.photos.length > minShowedPhotos"
      :class="{
        'show-box__button--more': showedPhotos <= minShowedPhotos,
        'show-box__button--less': showedPhotos > minShowedPhotos,
      }"
      @click="rollPhotos"
      class="show-box__button"
    >
      {{ showedPhotos === minShowedPhotos ? 'Zobrazit více' : 'Zobrazit méně' }}
    </button>

    <ModalPhoto
      v-if="selectedPhoto && isModalPhotoActive"
      v-model="isModalPhotoActive"
      :index="selectedPhoto.index"
      :photos="photos.map(([original]) => original)"
      :title="selectedPhoto.title"
    />
  </div>
</template>

<script>
import ModalPhoto from '@/components/ModalPhoto.vue';

export default {
  components: {
    ModalPhoto,
  },
  props: {
    photos: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minShowedPhotos: window.innerWidth > 740 ? 3 : 1,
      showedPhotos: window.innerWidth > 740 ? 3 : 1,
      selectedPhoto: null,
      isModalPhotoActive: false,
    };
  },
  computed: {
    photosToShow() {
      return this.photos.slice(0, this.showedPhotos);
    },
  },
  methods: {
    rollPhotos() {
      this.showedPhotos = this.showedPhotos > this.minShowedPhotos ? this.minShowedPhotos : this.photos.length;

      if (this.showedPhotos === this.minShowedPhotos) {
        this.$refs.showBox.scrollIntoView();
      }
    },
    showPhoto(title, index) {
      this.selectedPhoto = { title, index };
      this.isModalPhotoActive = true;
    },
  },
  watch: {
    'window.innerWidth': function innerWidth() {
      const newMin = window.innerWidth > 740 ? 3 : 1;

      this.showedPhotos = this.showedPhotos === this.minShowedPhotos ? newMin : this.showedPhotos;
      this.minShowedPhotos = newMin;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';

.show-box {
  border-bottom: 2px solid $color-light-grey;
  padding-bottom: 20px;
  padding-top: 60px;

  h2 {
    @include page-headline;
  }

  &__photos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      flex: 0 0 calc(33% - 3px);
      margin-right: 10px;
      margin-top: 10px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &--mobile {
      & > * {
        flex: 0 0 100%;
        margin-right: 0;
      }
    }
  }

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-light-grey;
    height: 190px;
    cursor: pointer;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__button {
    margin-top: 20px;
    background: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    &--more {
      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid $color-red;
        border-right: 1px solid $color-red;
        transform: rotate(46deg) skew(-17deg, -17deg);
      }
    }

    &--less {
      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-left: 1px solid $color-red;
        border-top: 1px solid $color-red;
        transform: rotate(46deg) skew(-17deg, -17deg);
      }
    }
  }
}
</style>
