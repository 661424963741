<template>
  <main class="contact">
    <CoverPhoto shadow>{{ $t('contact.title') | upperCase }}</CoverPhoto>

    <p>Stavební společnost Krampera s.r.o.<br/>Hořetice 7, 257 56 Nevelkov</p>

    <p><b>IČO</b>: 07646836</p>
    <p><b>DIČ</b>: CZ07646836</p>
    <p><b>ID datové schránky</b>: fe6oq3</p>
    <p>Společnost je vedená u městského soudu v Praze oddíl C vložka 304690</p>

    <div class="contact__content">
      <div class="contact__left">
        <IconPhone width="30" height="30"/>
        <div class="contact__phones">
          <a href="tel:+420604202940">+420 604 202 940</a>
          <a href="tel:+420605730619">+420 605 730 619</a>
        </div>

        <IconEmail width="30" height="30"/>
        <div class="contact__emails">
          <a href="mailto:frantisek@krampera-stavby.cz">frantisek@krampera-stavby.cz</a>
          <a href="mailto:martin@krampera-stavby.cz">martin@krampera-stavby.cz</a>
        </div>
      </div>

      <form class="contact__right" @submit="sendEmail">
        <div
          class="contact__form-element"
          :class="{ 'contact__form-element--error': !!validations.email && submited }"
        >
          <label for="email">E-mail</label>
          <input id="email" ref="email" v-model="email" type="email" required>
          <p class="contact__form-validation">
            {{ validations.email && submited ? validations.email : '' }}
          </p>
        </div>

        <div
          class="contact__form-element"
          :class="{ 'contact__form-element--error': !!validations.phone && submited }"
        >
          <label for="phone">Telefonní číslo</label>
          <input id="phone" v-model="phone" type="tel" maxlength="50" required>
          <p class="contact__form-validation">
            {{ validations.phone && submited ? validations.phone : '' }}
          </p>
        </div>

        <div
          class="contact__form-element contact__form-element--textarea"
          :class="{ 'contact__form-element--error': !!validations.message && submited }"
        >
          <label for="message">Text zprávy</label>
          <textarea id="message" v-model="message" type="text" maxlength="500" required />
          <p class="contact__form-validation">
            {{ validations.message && submited ? validations.message : '' }}
          </p>
        </div>

        <div v-if="successfulySent" class="contact__form-message contact__form-message--success">
          <p>Zpráva byla úspěšně odeslána</p>
        </div>

        <div v-if="error" class="contact__form-message contact__form-message--error">
          <h4>Error</h4>
          <p>{{ error.message }}</p>
        </div>

        <button class="button" @click.prevent="sendEmail">
          Odeslat zprávu
        </button>

        <Loader v-if="loading" :message="'Odesílám zprávu...'" />
      </form>
    </div>
  </main>
</template>

<script>
import { functions } from '@/firebase';
import { generateMetaTags } from '@/tools/meta';
import IconPhone from '@/components/icons/IconPhone.vue';
import IconEmail from '@/components/icons/IconEmail.vue';
import Loader from '@/components/Loader.vue';

export default {
  components: {
    IconPhone,
    IconEmail,
    Loader,
  },
  data() {
    return {
      // FORM DATA
      email: '',
      phone: '',
      message: '',
      // VALIDATIONS
      validations: {
        email: '',
        phone: '',
        message: '',
      },
      successfulySent: false,
      error: null,
      submited: false,
      loading: false,
    };
  },
  computed: {
    isFormValid() {
      this.validateEmail();
      this.validatePhone();
      this.validateMessage();

      return Object.values(this.validations).find((validation) => !!validation) === undefined;
    },
  },
  methods: {
    validateEmail() {
      // eslint-disable-next-line max-len
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = regexp.test(String(this.email).toLowerCase());

      if (!this.email) {
        this.validations.email = 'Pro odeslání zprávy musí být vyplněn e-mail.';
      } else if (!result) {
        this.validations.email = 'Vámi vyplněný e-mail není ve správném formátu.';
      } else {
        this.validations.email = '';
      }
    },
    validatePhone() {
      if (!this.phone) {
        this.validations.phone = 'Pro odeslání zprávy musí být vyplněné telefonní číslo.';
      } else {
        this.validations.phone = '';
      }
    },
    validateMessage() {
      if (!this.message) {
        this.validations.message = 'Pro odeslání zprávy musí být vyplněna zpráva.';
      } else if (this.message.lenght > 500) {
        this.validations.message = 'Zpráva nesmí obsahovat více než 500 znaků';
      } else {
        this.validations.message = '';
      }
    },
    sendEmail() {
      this.submited = true;

      if (this.isFormValid && !this.loading) {
        this.loading = true;

        const sendEmail = functions.httpsCallable('sendEmail');

        sendEmail({
          subject: 'E-mail z formuláře krampera-stavby.cz',
          text: `E-mail: ${this.email}
          Mobilní číslo: ${this.phone}
          Text zprávy: ${this.message}`,
          html: `<p>E-mail: <a href="mailto:${this.email}">${this.email}</a></p>
          <p>Mobilní číslo: <a href="tel:${this.phone}">${this.phone}</a></p><br/>
          <b>Text zprávy:</b><br/>
          <p>${this.message}</p><br/>`,
        }).then(() => {
          this.successfulySent = true;
          this.loading = false;
          this.submited = false;
          this.email = '';
          this.phone = '';
          this.message = '';
        }).catch((error) => {
          this.error = error;
          this.loading = false;
        });
      }
    },
  },
  watch: {
    successfulySent() {
      setTimeout(() => { this.successfulySent = false; }, 3000);
    },
    email() { this.validateEmail(); },
    phone() { this.validatePhone(); },
    message() { this.validateMessage(); },
  },
  metaInfo() {
    return {
      titleTemplate: `%s - ${this.$t('contact.title').toLowerCase()}`,
      meta: [...generateMetaTags(
        this.$t('contact.title').toLowerCase(),
        this.$t('contact.description'),
        'https://krampera-stavby.cz/kontakty',
      )],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    @include page-headline;
  }

  p {
    margin: 10px 0;
  }

  &__content {
    display: flex;
    align-items: center;
    background-color: $color-light-grey;
    padding: 20px;
    width: 60%;
    margin-top: 40px;
    border-radius: 6px;

    @media screen and (max-width: 740px) {
      flex-direction: column;
    }

    & > * {
      flex: 0 0 calc(50% - 10px);

      @media screen and (max-width: 740px) {
        flex: auto;
      }

      &:first-child {
        margin-right: 20px;

        @media screen and (max-width: 740px) {
          margin-right: 0;
          margin-bottom: 40px;
        }
      }
    }
  }

  &__phones, &__emails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      text-align: center;
    }
  }

  &__phones {
    margin: 10px 0 25px;
  }

  &__emails {
    margin: 10px 0 0;
  }

  &__contacts-box {
    padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__right {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    & button {
      min-height: 28px;
      width: 30%;
      background-color: $color-red;
      color: $color-white;
      cursor: pointer;

      @media screen and (max-width: 620px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 620px) {
      width: 100%;
    }

    & > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form-validation {
    margin: 0 !important;
    padding: 2px 0 !important;
    font-size: 0.8rem;
    font-weight: 400;
    width: 100%;
    color: $color-red;
    text-align: left !important;
  }

  &__form-message {
    padding: 5px 20px;
    border-radius: 3px;

    &--error {
      background-color: $color-red;
      color: $color-white;
    }

    &--success {
      background-color: green;
      color: $color-white;
    }

    & p {
      font-weight: 300;
    }
  }

  &__form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    textarea, input {
      border: 1px solid $color-white;
      padding: 5px 10px;
    }

    &--error {
      & textarea, input {
        border: 1px solid $color-red;
      }
    }

    &--textarea {
      height: 200px;

      & textarea {
        height: 100%;
        resize: none;

        @media screen and (max-width: 620px) {
          height: 100px;
        }
      }
    }
  }
}
</style>
