<template>
  <div id="app">
    <Header />
    <!-- <CoverPhoto/> -->
    <div class="content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
// import CoverPhoto from '@/components/layout/CoverPhoto.vue';

export default {
  components: {
    Header,
    Footer,
    // CoverPhoto,
  },
  metaInfo: {
    title: 'Krampera Stavby',
    meta: [
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: 'https://krampera-stavby.cz/cover-photo.jpeg' },
      { property: 'og:local', content: 'cs_CZ' },
      { property: 'twitter:card', content: 'summary' },
    ],
  },
};
</script>

<style lang="scss">
.content {
  margin-top: calc(70vh + #{$header-height});
}
</style>
