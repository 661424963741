<template>
  <main class="technology">
    <CoverPhoto shadow>{{ $t('technology.title') | upperCase }}</CoverPhoto>

    <!-- MECHS -->
    <ShowBox
      v-for="({ title, text, photos }, index) in technology"
      :key="`technoka-${title}-${index}`"
      :title="title"
      :photos="photos"
    >
      <template v-slot:title>
        {{ title | upperCase }}
      </template>

      <template v-slot:description>
        <span v-html="text"/>
      </template>
    </ShowBox>
  </main>
</template>

<script>
/* eslint-disable max-len */
import { generateMetaTags } from '@/tools/meta';
import ShowBox from '@/components/ShowBox.vue';
import { storage } from '@/firebase';
import technology from '@/mocks/technology';

export default {
  components: {
    ShowBox,
  },
  data() {
    return {
      technology: technology || [],
      urls: [],
    };
  },
  created() {
    this.technology.forEach(({ photosPath }, index) => {
      if (photosPath) this.getPhotos(photosPath, index);
    });
  },
  methods: {
    async getPhotos(path, index) {
      const refOriginal = storage.ref().child(path);
      const refSmall = storage.ref().child(`${path}/Small`);
      const originals = await refOriginal.listAll();
      const smalls = await refSmall.listAll();
      const promises = [];

      if (originals && smalls) {
        originals.items.forEach((originalRef) => {
          const imgName = originalRef.name;
          const smallRef = smalls.items.find(({ name }) => imgName === name);

          if (originalRef && smallRef) {
            promises.push(new Promise((res, rej) => {
              const urlPromises = [originalRef.getDownloadURL(), smallRef.getDownloadURL()];

              Promise.all(urlPromises)
                .then((data) => res(data))
                .catch((error) => rej(error));
            }));
          }
        });

        const urls = await Promise.all(promises);

        if (urls) {
          this.technology[index].photos = urls;
        }
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s - ${this.$t('technology.title').toLowerCase()}`,
      meta: [...generateMetaTags(
        this.$t('technology.title').toLowerCase(),
        this.$t('technology.description'),
        'https://krampera-stavby.cz/technika',
      )],
    };
  },
};
</script>
