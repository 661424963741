<template>
  <svg
    :width="width"
    :height="height"
    role="presentation"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 18"
  >
    <g>
      <slot />
    </g>
  </svg>
</template>

<script>
import { iconMixin } from '@/mixins';

export default {
  mixins: [iconMixin],
};
</script>
