import Vue from 'vue';
import Vuex from 'vuex';

// MODULES

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {},
});
