<template>
  <footer class="footer">
    <a
      href="https://www.facebook.com/Krampera-stavby-103605058466055"
      target="_blank"
      rel="noopener noreferrer"
      class="footer__facebook"
    >
      <IconFacebook width="30" height="30"/>
      <span class="footer__hidden-link-name">
        {{ $t('header.facebook') }}
      </span>
    </a>

    <div class="footer__emails">
      <a href="mailto:frantisek@krampera-stavby.cz">frantisek@krampera-stavby.cz</a>
      <a href="mailto:martin@krampera-stavby.cz">martin@krampera-stavby.cz</a>
    </div>

    <div class="footer__phones">
      <a href="tel:+420604202940">+420 604 202 940</a>
      <a href="tel:+420605730619">+420 605 730 619</a>
    </div>
  </footer>
</template>

<script>
import IconFacebook from '@/components/icons/IconFacebook.vue';

export default {
  components: {
    IconFacebook,
  },
};
</script>

<style lang="scss">
.footer {
  padding: 10px 20px;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-grey;

  a {
    color: $color-white;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 2px solid $color-red;
    padding-right: 30px;
    padding-left: 30px;

    @media screen and (max-width: 740px) {
      padding-right: 0;
      padding-left: 0;
      border-right: none;

      &::after {
        content: '';
        width: 100px;
        height: 2px;
        background-color: $color-red;
        display: block;
        margin: 15px 0;
      }
    }

    &:last-child {
      border-right: none;
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__facebook {
    color: $color-black !important;
  }

  &__hidden-link-name {
    position: absolute;
    top: 0;
    line-height: 0;
    font-size: 0;
    color: transparent;
    width: 0 !important;
  }
}
</style>
