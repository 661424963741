<template>
  <!-- eslint-disable --> 
  <IconBase v-bind="iconBaseProps">
    <g id="Vrstva_2" data-name="Vrstva 2">
      <g id="Vrstva_1-2" data-name="Vrstva 1">
        <path class="icon-phone" d="M17.98763,14.81511a1.38112,1.38112,0,0,0-.685-1.02314L12.862,11.23521a1.39347,1.39347,0,0,0-1.67778.2219l-.87467.87468a.641.641,0,0,1-.85636.04785,25.27781,25.27781,0,0,1-2.0179-1.81493,25.28613,25.28613,0,0,1-1.81493-2.0179.641.641,0,0,1,.04785-.8564l.87464-.87467A1.39327,1.39327,0,0,0,6.76476,5.138L4.208.69734A1.39112,1.39112,0,0,0,2.01873.40779L.70079,1.72577c-.91985.91988-.93462,2.60252-.0416,4.738a20.92506,20.92506,0,0,0,4.51216,6.36483,20.9242,20.9242,0,0,0,6.36487,4.5122A7.42235,7.42235,0,0,0,14.32352,18a2.65826,2.65826,0,0,0,1.95072-.70082l1.31794-1.318a1.38124,1.38124,0,0,0,.39545-1.16609ZM12.571,11.74056l4.44066,2.55676a.8078.8078,0,0,1,.24679,1.18l-5.90159-3.36815.23967-.23967a.80941.80941,0,0,1,.97447-.12891ZM3.10835.59047a.80206.80206,0,0,1,.59426.39786L6.25937,5.429a.80921.80921,0,0,1-.12887.97443l-.22016.22015L2.52369.7415A.79816.79816,0,0,1,3.00031.58331a.82389.82389,0,0,1,.108.00716ZM15.86184,16.88682c-.73079.73079-2.22542.70016-4.10065-.08405a20.33572,20.33572,0,0,1-6.17745-4.3865A20.33569,20.33569,0,0,1,1.1972,6.23882C.413,4.36355.38235,2.86892,1.11315,2.13809l.97839-.97844,3.39175,5.891-.22747.22746A1.22336,1.22336,0,0,0,5.166,8.91244a25.8722,25.8722,0,0,0,1.85688,2.06463A25.8829,25.8829,0,0,0,9.08753,12.834a1.22329,1.22329,0,0,0,1.63435-.08988l.20752-.20752,5.90979,3.37285-.97735.97738Z"/>
      </g>
    </g>
  </IconBase>
</template>

<script>
import { iconMixin } from '@/mixins';
import IconBase from './IconBase.vue';

export default {
  mixins: [iconMixin],
  components: {
    IconBase,
  },
};
</script>

<style lang="scss" scoped>
.icon-phone {
  fill: $color-red;
}
</style>
