<template>
  <!-- eslint-disable --> 
  <IconBase v-bind="iconBaseProps">
    <g id="Vrstva_2" data-name="Vrstva 2">
      <g id="Layer_1" data-name="Layer 1">
        <path class="icon-email" d="M16.5,0H1.5A1.50146,1.50146,0,0,0,0,1.5V12a1.50146,1.50146,0,0,0,1.5,1.5h15A1.50147,1.50147,0,0,0,18,12V1.5A1.50147,1.50147,0,0,0,16.5,0ZM1.5.75h15a.71374.71374,0,0,1,.15582.03153C15.3572,1.97005,11.05112,5.90938,9.54422,7.26708a.75225.75225,0,0,1-1.08876-.00035C6.9487,5.90924,2.64238,1.9697,1.344.78159A.7168.7168,0,0,1,1.5.75ZM.75,12V1.5a.72476.72476,0,0,1,.042-.20728c.99394.9097,3.99854,3.65836,5.94675,5.43-1.94189,1.66806-4.94725,4.5174-5.9491,5.47274A.72286.72286,0,0,1,.75,12Zm15.75.75H1.5a.71734.71734,0,0,1-.1691-.03424c1.03525-.98691,4.05974-3.85263,5.96749-5.48469.2487.2256.47588.43129.655.59266a1.498,1.498,0,0,0,2.09289.00035c.17916-.16143.40651-.36731.65535-.593,1.90786,1.63188,4.93193,4.4974,5.96749,5.48469A.71663.71663,0,0,1,16.5,12.75Zm.75-.75a.72465.72465,0,0,1-.03958.19547c-1.00224-.95583-4.00726-3.80486-5.94911-5.47271,1.94829-1.77162,4.95243-4.52,5.94671-5.43009A.72463.72463,0,0,1,17.25,1.5Z"/>
      </g>
    </g>
  </IconBase>
</template>

<script>
import { iconMixin } from '@/mixins';
import IconBase from './IconBase.vue';

export default {
  mixins: [iconMixin],
  components: {
    IconBase,
  },
};
</script>

<style lang="scss" scoped>
.icon-email {
  fill: $color-red;
}
</style>
