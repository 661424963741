<template>
  <!-- eslint-disable --> 
  <IconBase v-bind="iconBaseProps">
    <g id="Vrstva_facebook_2" data-name="Vrstva 2">
      <g id="Capa_facebook_1" data-name="Capa 1">
        <circle class="icon-facebook" cx="9" cy="9" r="9"/>
        <path d="M11.35412,4.29309l-1.22127-.002A2.14476,2.14476,0,0,0,7.87411,6.60885V7.67747H6.64618a.19205.19205,0,0,0-.192.19213V9.41792a.192.192,0,0,0,.192.192H7.87411v3.90689a.192.192,0,0,0,.192.192h1.6021a.192.192,0,0,0,.192-.192V9.61H11.296a.192.192,0,0,0,.192-.192l.00059-1.54832a.19219.19219,0,0,0-.19213-.19213H9.86028V6.77159c0-.43541.10375-.65644.67094-.65644l.8227-.0003a.192.192,0,0,0,.19194-.192V4.48512A.19209.19209,0,0,0,11.35412,4.29309Z"/>
      </g>
    </g>
  </IconBase>
</template>

<script>
import { iconMixin } from '@/mixins';
import IconBase from './IconBase.vue';

export default {
  mixins: [iconMixin],
  components: {
    IconBase,
  },
};
</script>

<style lang="scss">
.icon-facebook {
  color: #ffffff;
  fill: #ffffff;
}
</style>
