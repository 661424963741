import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/registerServiceWorker';
import { upperCase } from '@/filters';
import CoverPhoto from '@/components/layout/CoverPhoto.vue';

Vue.config.productionTip = false;

Vue.use(VueMeta);

Vue.filter('upperCase', upperCase);

Vue.component('CoverPhoto', CoverPhoto);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
