var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"references"},[_c('CoverPhoto',{attrs:{"shadow":""}},[_vm._v(_vm._s(_vm._f("upperCase")(_vm.$t('references.title'))))]),_c('h2',[_vm._v(_vm._s(_vm._f("upperCase")(("" + (_vm.$t('about.ourClients'))))))]),_c('div',{staticClass:"references__clients"},_vm._l((_vm.clients),function(ref,index){
var name = ref.name; if ( name === void 0 ) name = '';
var prefix = ref.prefix; if ( prefix === void 0 ) prefix = '';
return _c('div',{key:("client-" + index),staticClass:"references__client"},[_c('h3',{staticClass:"references__client-name"},[(prefix)?_c('span',{staticClass:"references__client-prefix"},[_vm._v(" "+_vm._s(prefix)+" ")]):_vm._e(),_vm._v(" "+_vm._s(name)+" ")])])}),0),_vm._l((_vm.references),function(ref,index){
var title = ref.title;
var text = ref.text;
var photos = ref.photos;
return _c('ShowBox',{key:("reference-" + title + "-" + index),attrs:{"title":title,"photos":photos},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("upperCase")(title))+" ")]},proxy:true},{key:"description",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(text)}})]},proxy:true}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }