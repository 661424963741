export const generateMetaTags = (title, description, url) => [
  { property: 'og:url', content: url },
  { property: 'og:title', content: `Krampera Stavby - ${title}` },
  { property: 'og:site_name', content: `Krampera Stavby - ${title}` },
  { property: 'og:description', content: description },
  { name: 'description', content: description },
];

export default {
  generateMetaTags,
};
