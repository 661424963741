<template>
  <main class="references">
    <CoverPhoto shadow>{{ $t('references.title') | upperCase }}</CoverPhoto>
    <!-- CLIENTS -->
    <h2>{{ `${$t('about.ourClients')}` | upperCase }}</h2>
    <div class="references__clients">
      <div
        v-for="({ name = '', prefix = '' }, index) in clients"
        :key="`client-${index}`"
        class="references__client"
      >
        <h3 class="references__client-name">
          <span v-if="prefix" class="references__client-prefix">
            {{ prefix }}
          </span>
          {{ name }}
        </h3>
      </div>
    </div>

    <!-- REFERENCES -->
    <ShowBox
      v-for="({ title, text, photos }, index) in references"
      :key="`reference-${title}-${index}`"
      :title="title"
      :photos="photos"
    >
      <template v-slot:title>
        {{ title | upperCase }}
      </template>

      <template v-slot:description>
        <span v-html="text"/>
      </template>
    </ShowBox>
  </main>
</template>

<script>
/* eslint-disable max-len */
import { generateMetaTags } from '@/tools/meta';
import ShowBox from '@/components/ShowBox.vue';
import { storage } from '@/firebase';
import references from '@/mocks/references';

export default {
  components: {
    ShowBox,
  },
  data() {
    return {
      clients: [
        { prefix: '', name: 'BES s.r.o.' },
        { prefix: 'Město', name: 'Benešov' },
        { prefix: 'Město', name: 'Týnec nad Sázavou' },
        { prefix: 'Obec', name: 'Petroupim' },
        { prefix: 'Obec', name: 'Nespeky' },
        { prefix: 'Obec', name: 'Struhařov' },
        { prefix: 'Obec', name: 'Velké Popovice' },
        { prefix: 'Obec', name: 'Prosečnice' },
      ],
      references: references || [],
    };
  },
  async created() {
    this.references.forEach(({ photosPath }, index) => this.getPhotos(photosPath, index));
  },
  methods: {
    async getPhotos(path, index) {
      const refOriginal = storage.ref().child(path);
      const refSmall = storage.ref().child(`${path}/Small`);
      const originals = await refOriginal.listAll();
      const smalls = await refSmall.listAll();
      const promises = [];

      if (originals && smalls) {
        originals.items.forEach((originalRef) => {
          const imgName = originalRef.name.toLowerCase();
          const smallRef = smalls.items.find(({ name }) => imgName === name.toLowerCase());

          if (originalRef && smallRef) {
            promises.push(new Promise((res, rej) => {
              const urlPromises = [originalRef.getDownloadURL(), smallRef.getDownloadURL()];

              Promise.all(urlPromises)
                .then((data) => res(data))
                .catch((error) => rej(error));
            }));
          }
        });

        const urls = await Promise.all(promises);

        if (urls) {
          this.references[index].photos = urls;
        }
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s - ${this.$t('references.title').toLowerCase()}`,
      meta: [...generateMetaTags(
        this.$t('references.title').toLowerCase(),
        this.$t('references.description'),
        'https://krampera-stavby.cz/reference',
      )],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';

.references {
  h2 {
    @include page-headline;
  }

  &__clients {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 0 0 calc(25% - 48px);
      margin-right: 10px;
      margin-top: 10px;

      @media screen and (max-width: 620px) {
        flex: 0 0 calc(33% - 45px);
      }

      @media screen and (max-width: 550px) {
        flex: 0 0 calc(50% - 45px);
      }

      &:nth-child(2n) {
        @media screen and (max-width: 550px) {
          margin-right: 0;
        }
      }

      &:nth-child(3n) {
        @media screen and (max-width: 620px) {
          margin-right: 0;
        }

        @media screen and (max-width: 550px) {
          margin-right: 10px;
        }
      }

      &:nth-child(4n) {
        margin-right: 0;

        @media screen and (max-width: 620px) {
          margin-right: 10px;
        }

         @media screen and (max-width: 550px) {
          margin-right: 0;
        }
      }

      &:nth-child(6n) {
        @media screen and (max-width: 550px) {
          margin-right: 0;
        }
      }
    }
  }

  &__client {
    padding: 10px 20px;
    background-color: $color-light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__client-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
  }

  &__client-prefix {
    font-weight: 100;
  }
}
</style>
