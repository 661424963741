<template>
  <div @click.self="isActive = false" class="modal-photo">
    <div class="modal-photo__photo">
      <button
        @click="isActive = false"
        class="modal-photo__close"
      >
        x
      </button>
      <button @click="prev" class="modal-photo__navigation modal-photo__navigation--left"/>
      <img :alt="title" :src="photos[actualIndex]"/>
      <button @click="next" class="modal-photo__navigation modal-photo__navigation--right"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    photos: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actualIndex: this.index,
      isActive: this.value,
    };
  },
  created() {
    this.preLoadImages();
  },
  methods: {
    prev() {
      this.actualIndex = this.actualIndex > 0 ? this.actualIndex - 1 : this.photos.length - 1;
    },
    next() {
      this.actualIndex = this.actualIndex < this.photos.length - 1 ? this.actualIndex + 1 : 0;
    },
    preLoadImages() {
      const indexes = [
        this.actualIndex - 2 < 0 ? this.actualIndex - 2 + this.photos.length : this.actualIndex - 2,
        this.actualIndex - 1 < 0 ? this.photos.length - 1 : this.actualIndex - 1,
        this.actualIndex + 1 > this.photos.length - 1 ? 0 : this.actualIndex + 1,
        this.actualIndex + 2 > this.photos.length - 1 ? this.actualIndex + 2 - this.photos.length : this.actualIndex + 2,
      ];

      indexes.forEach((index) => {
        console.log(index);
        const image = new Image();

        image.src = this.photos[index];
      });
    },
  },
  watch: {
    value() {
      this.isActive = this.value;
    },
    isActive() {
      this.$emit('input', this.isActive);
    },
    actualIndex() {
      this.preLoadImages();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-photo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  &__photo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
    background-color: $color-light-grey;

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &__navigation {
    position: absolute;
    top: 35px;
    bottom: 0;
    background: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &--left {
      left: 10px;

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-left: 3px solid $color-dark-grey;
        border-top: 3px solid $color-dark-grey;
        transform: rotate(-46deg) skew(-17deg, -17deg);
      }
    }

    &--right {
      right: 10px;

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-left: 3px solid $color-dark-grey;
        border-top: 3px solid $color-dark-grey;
        transform: rotate(136deg) skew(-17deg, -17deg);
      }
    }
  }
}
</style>
