<template>
  <!-- eslint-disable --> 
  <header ref="header" class="header">
    <div class="header__wrapper">
      <nav class="header__nav header__nav--desktop">
        <router-link :to="{ name: 'Home' }" class="header__nav-box">
          <div class="header__nav-logo">
            <img alt="Logo" src="https://firebasestorage.googleapis.com/v0/b/krampera-stavby.appspot.com/o/logo.png?alt=media&token=2bfeb9a1-f5d4-42d2-b589-9c453abcdcca"/>
          </div>
          <span class="header__hidden-link-name">
            {{ $t('header.home') }}
          </span>
        </router-link>

        <ul class="header__nav-items header__nav-box--nav">
          <li
            v-for="({ name, text, isActive }, index) in nav"
            :key="`nav-desktop-${name}-${index}`"
            :class="{ 'header__nav-item--active': isActive }"
            class="header__nav-item"
          >
            <router-link :to="{ name }">{{ text }}</router-link>
          </li>
        </ul>

        <a
          href="https://www.facebook.com/Krampera-stavby-103605058466055"
          target="_blank"
          rel="noopener noreferrer"
          class="header__nav-box header__nav-contact"
        >
          <IconFacebook width="30" height="30"/>
          <span class="header__hidden-link-name">
            {{ $t('header.facebook') }}
          </span>
        </a>
      </nav>

      <nav class="header__nav header__nav--mobile">
        <button @click="toggleMobileNav" class="header__nav-box header__nav-burger">
          <span/>
          <span/>
          <span class="header__hidden-link-name">
            {{ $t('header.burger') }}
          </span>
        </button>

        <router-link
          :to="{ name: 'Home' }"
          @click="toggleMobileNav(false)"
          class="header__nav-home header__nav-home--mobile header__nav-box"
        >
          <div @click="toggleMobileNav(false)" class="header__nav-logo">
            <img alt="logo" src="https://firebasestorage.googleapis.com/v0/b/krampera-stavby.appspot.com/o/logo.png?alt=media&token=2bfeb9a1-f5d4-42d2-b589-9c453abcdcca"/>
          </div>
          <span class="header__hidden-link-name">
            {{ $t('header.home') }}
          </span>
        </router-link>

        <a
          @click="toggleMobileNav(false)"
          href="https://www.facebook.com/Krampera-stavby-103605058466055"
          target="_blank"
          rel="noopener noreferrer"
          class="header__nav-contact header__nav-box"
        >
          <IconFacebook width="30" height="30"/>
          <span class="header__hidden-link-name">
            {{ $t('header.facebook') }}
          </span>
        </a>
      </nav>

      <nav ref="mobileNav" class="header__nav-mobile">
        <ul class="header__nav-items header__nav-items--mobile">
          <li
            v-for="({ name, text, isActive }, index) in nav"
            :key="`nav-desktop-${name}-${index}`"
            :class="{ 'header__nav-item--active': isActive }"
            @click="toggleMobileNav(false)"
            class="header__nav-item"
          >
            <router-link
              :to="{ name }"
              @click="toggleMobileNav(false)"
            >
              {{ text | upperCase }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import IconFacebook from '@/components/icons/IconFacebook.vue';

export default {
  components: {
    IconFacebook,
  },
  computed: {
    nav() {
      return [
        { name: 'About', text: this.$t('header.about'), isActive: this.$route.name === 'About' || this.$route.path === '/' },
        { name: 'References', text: this.$t('header.references'), isActive: this.$route.name === 'References' },
        { name: 'Technology', text: this.$t('header.technology'), isActive: this.$route.name === 'Technology' },
        { name: 'Contact', text: this.$t('header.contact'), isActive: this.$route.name === 'Contact' },
      ];
    },
  },
  methods: {
    toggleMobileNav(toggle = true) {
      console.log('asd');
      if (toggle) {
        this.$refs.mobileNav.classList.toggle('header__nav-mobile--visible');
        this.$refs.header.classList.toggle('header--mobile--visible');
        document.body.classList.toggle('no-scroll');
      } else {
        this.$refs.mobileNav.classList.remove('header__nav-mobile--visible');
        this.$refs.header.classList.remove('header--mobile--visible');
        document.body.classList.remove('no-scroll');
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;
  padding: 0 $header-padding;
  transition: all .5s ease;
  background-color: rgba(0, 0, 0, 0.6);

  &--mobile--visible {
    background-color: $color-dark-grey;
  }

  &__wrapper {
    margin: 0 0 $section-margin 0;
  }

  &__hidden-link-name {
    position: absolute;
    top: 0;
    line-height: 0;
    font-size: 0;
    color: transparent;
    width: 0 !important;
  }

  &__nav {
    max-width: $app-width;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-content: center;
    position: relative;
    z-index: 1000;

    &--mobile {
      display: none;

      @media screen and (max-width: 740px) {
        display: flex;
      }
    }

    &--desktop {
      @media screen and (max-width: 740px) {
        display: none;
      }
    }
  }

  &__nav-mobile {
    display: block;
    position: fixed;
    top: calc(#{$header-height} - 1px);
    right: 0;
    width: 100%;
    height: calc(100vh - #{$header-height} + 1px);
    background-color: $color-black;
    transform: translateY(-100vh);
    transition: all .5s ease;
    z-index: 0;

    &--visible {
      background-color: $color-dark-grey;
      transform: translateY(0);
    }
  }

  &__nav-items {
    display: flex;
    justify-content: center;
    align-content: center;

    &--mobile {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;

      @media screen and (max-width: 740px) {
        display: flex;
        max-width: none;
        margin-top: 30px;
      }

      button {
        border: none;
      }
    }
  }

  &__nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;

    @media screen and (max-width: 740px) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &:last-child {
      margin: 0;
    }

    a {
      color: $color-white;
    }

    &--active {
      a {
        color: $color-red;
      }
    }
  }

  &__nav-box {
    position: relative;
    width: 120px;
    height: $header-height;
    padding: 10px;
    box-sizing: border-box;

    &--nav {
      width: 50%;
    }
  }

  &__nav-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 740px) {
      width: 50px;
    }
  }

  &__nav-burger {
    background: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50px;

    span {
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      display: block;
    }
  }
}
</style>
