<template>
  <!-- eslint-disable --> 
  <main class="about">
    <CoverPhoto/>
    <h1>{{ `${$t('about.title')}` | upperCase }}</h1>
    <p>Vážení zákazníci,<br/>vítáme vás na internetových stránkách Stavební společnosti Krampera s.r.o.</p>
    <p>Naše společnost vznikla v roce 2002 a působí  v oboru staveb v oblasti Středočeského kraje a Prahy. Vlastníme nejmodernější strojový park který neustále rozšiřujeme a díky pravidelným investicím do nejrůznějšího stavebního vybavení a zařízení jsme schopni zrealizovat prakticky jakoukoliv stavební zakázku.</p> 
    <p>Máme dlouholeté skušenosti díky kterým dokážeme operativně velice rychle najít optimální řešení pro každou stavbu. Nabízíme veškeré výkopové práce a terénní úpravy, stavby a rekonstrukce rodinných domů, inženýrských sítí, pokládku všech druhů dlažeb, dále provádíme kompletní stavby a rekonstrukce místních komunikací, chodníků a všech druhů oplocení včetně zděných plotů.</p>
    <p>Naší prioritou je vysoká kvalita odvedené práce a slušný a otevřený spůsob komunikace se zákazníkem.</p>
  </main>
</template>

<script>
import { generateMetaTags } from '@/tools/meta';

export default {
  metaInfo() {
    return {
      titleTemplate: `%s - ${this.$t('about.title').toLowerCase()}`,
      meta: [...generateMetaTags(
        this.$t('about.title').toLowerCase(),
        this.$t('about.description'),
        'https://krampera-stavby.cz/o-nas',
      )],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';

.about {
  h1 {
    @include page-headline;
  }
}
</style>
