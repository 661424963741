export const iconMixin = {
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    iconBaseProps() {
      return {
        iconName: this.iconName,
        width: this.width,
        height: this.height,
        iconColor: this.iconColor,
      };
    },
  },
};

export default {
  iconMixin,
};
