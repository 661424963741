<template>
  <div class="loader">
    <div class="loader__wrapper">
      <div class="loader__box">
        <div class="loader__spinner" />
        <p v-if="message">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  z-index: 1000;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  &__box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__spinner {
    animation: spin 3s linear infinite;
    border-top: 2px solid $color-black;
    border-right: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
