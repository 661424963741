import header from './header';
import footer from './footer';
import about from './about';
import contact from './contact';
import references from './references';
import technology from './technology';

export default {
  header,
  footer,
  about,
  contact,
  references,
  technology,
};
