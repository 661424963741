var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"showBox",staticClass:"show-box"},[_c('h2',[_vm._t("title")],2),_c('p',[_vm._t("description")],2),_c('div',{staticClass:"show-box__photos",class:{ 'show-box__photos--mobile': _vm.minShowedPhotos === 1 }},_vm._l((_vm.photosToShow),function(ref,index){
var small = ref[1];
return _c('div',{key:("photo-" + _vm.title + "-" + index),staticClass:"show-box__photo",on:{"click":function($event){return _vm.showPhoto((_vm.title + " - c." + index), index)}}},[_c('img',{attrs:{"alt":(_vm.title + " - c." + index),"src":small,"loading":"lazy"}})])}),0),(this.photos.length > _vm.minShowedPhotos)?_c('button',{staticClass:"show-box__button",class:{
      'show-box__button--more': _vm.showedPhotos <= _vm.minShowedPhotos,
      'show-box__button--less': _vm.showedPhotos > _vm.minShowedPhotos,
    },on:{"click":_vm.rollPhotos}},[_vm._v(" "+_vm._s(_vm.showedPhotos === _vm.minShowedPhotos ? 'Zobrazit více' : 'Zobrazit méně')+" ")]):_vm._e(),(_vm.selectedPhoto && _vm.isModalPhotoActive)?_c('ModalPhoto',{attrs:{"index":_vm.selectedPhoto.index,"photos":_vm.photos.map(function (ref) {
        var original = ref[0];

        return original;
}),"title":_vm.selectedPhoto.title},model:{value:(_vm.isModalPhotoActive),callback:function ($$v) {_vm.isModalPhotoActive=$$v},expression:"isModalPhotoActive"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }